export const environment = {
	name: 'verification',
	production: true,
	domainUserAPI: "https://api-ver2.orthonext.app/usr/v1",
	cdnUrl: 'https://cdnver2.orthonext.app/ver',
	insightKey: '5666fdb0-4bd1-494e-a92a-151101ab7cb3',
	azureMapsKey: 'sgdF-w6qC3HfMY1yhhxmRV3RnAgYnrnENdgMylARww8',
	buildVer: 'OrthoNext-UserRequest FE VER CI-CD_24107.01',
	orthofixWebsite: 'https://www.orthofix.com',
	orthofixMedicalEducation: 'https://www.orthofixacademy.com',
	customerCareEmail: 'orthonext@orthofix.com',
	recaptchaSiteKey: '6LdEW4EpAAAAANsCWHaXOPe3EcDxQXkghuoBsS0T',
	b2cTenantName: 'orthonextb2cver2',
  b2cTenantId: '262e6e85-9536-464a-bb73-0bb0c09a36e8',
  b2cSignInPolicyId: 'B2C_1_OnlySignIN2',
  b2cResetPasswordPolicyId: 'B2C_1_PasswordReset',
  b2cChangePasswordPolicyId: 'B2C_1A_PasswordChange',
  b2cClientId: 'f3c6d7b4-e0ae-41eb-a7e9-17d08f2fab9e',
  b2cApplicationScope: 'openid profile https://orthonextb2cver2.onmicrosoft.com/api/user_impersonation',
  appSSOutUriApi: 'https://api-ver2.orthonext.app/services/appssouturi',
  idleTimeToEndSession: 1800,
	patplanSite: 'https://patver2.orthonext.app',
	cookieName: '_iub_cs-60532175'
};
